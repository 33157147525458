import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { GlobalState } from "context-api";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { getMyPractitioners } from "Utils/Services/Hospital/Practitioner";
import {
  AddPatientByInstantBooking,
  CheckPatientExist,
  PostApponitmentCheckout,
} from "Utils/Services/Hospital/QuickBooking";
import ScheduleCard from "views/components/quickbooking/ScheduleCard";
import PhoneInputComponent from "../../../../components/PhoneInput";
import ConsultationTypesCard from "views/components/quickbooking/ConsultationTypesCard";
import AntdCustomMessage, { antdMessageTypes } from "components/AntdCustomMessage";
import { useForm } from "antd/lib/form/Form";

const QuickBooking = () => {
  const { setHeading, Heading } = useContext(GlobalState);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [isPatientExist, setIsPatientExist] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [error, setError] = useState({
    isError: false,
    message: [],
  });
  const [myPractitioners, setMyPractitioners] = useState(null);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [appointmentType, setAppointmentType] = useState(null);

  const [mobileNumber, setMobileNumber] = useState({
    mobile: "",
    country: "ae",
  });

  const [ButtonLoading, setButtonLoading] = useState(false);
  const [patientExistsForm] = useForm();
  const [newPatientForm] = useForm();

  useEffect(() => {
    if (setHeading && Heading) {
      setHeading("Instant Booking");
    }
  }, [setHeading, Heading]);

  useEffect(() => {
    const fetchData = async () => {
      const myPractitionersResponse = await getMyPractitioners(null);

      myPractitionersResponse && setMyPractitioners(myPractitionersResponse);
    };
    fetchData();
  }, []);

  const checkPatientExists = async (value) => {
    if (value.dialercode === "91" && value.mobile.length !== 10) {
      //message.error("Enter valid mobile number");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Enter valid mobile number"
      });
      return;
    }
    if (value.dialercode === "971" && value.mobile.length !== 9) {
      //message.error("Enter valid mobile number");
      AntdCustomMessage({
        type: antdMessageTypes.ERROR,
        content: "Enter valid mobile number"
      });
      return;
    }

    setIsBtnLoading(true);
    setSelectedPractitioner(null);
    setAppointmentDate(null);
    setIsPatientExist(null);
    setPatientData(null);
    setError({
      isError: false,
      message: [],
    });

    const { status, patientExist, data } = await CheckPatientExist(value);
    if (status && patientExist) {
      setIsPatientExist(true);
      setPatientData(data);
    } else if (status && !patientExist) {
      setIsPatientExist(false);
      setPatientData(null);
    } else {
      setError({
        isError: true,
        message: data,
      });
    }
    setIsBtnLoading(false);
  };
  const addBookingWithNewPatient = async (values) => {
    setButtonLoading(true);
    let { first_name, last_name, dob, gender } = values;

    const addPatientResponse = await AddPatientByInstantBooking({
      first_name,
      last_name,
      dob: moment(dob).format("YYYY-MM-DD"),
      gender,
      dialercode: mobileNumber.country,
      mobile: mobileNumber.mobile.substring(mobileNumber.country.length),
    });
    if (addPatientResponse && addPatientResponse?.status === 200) {
      await addBooking(
        addPatientResponse?.data?.patient_id,
        selectedPractitioner,
        appointmentDate?.date,
        appointmentDate?.time,
        appointmentDate?.token,
        "1",
        "0",
        addPatientResponse?.data?.password
      );
    }
    setButtonLoading(false);
  };

  const addBooking = async (
    patient_id,
    practitioner_id,
    appointment_date,
    appointment_time,
    appointment_token,
    booking_type_id,
    existing = 1,
    password = null,
  ) => {
    let object = {
      patient_id: "SDP" + patient_id,
      practitioner_id,
      appointment_date: moment(appointment_date).format("DD-MMM-YYYY"),
      appointment_time,
      token: appointment_token,
      booking_type_id,  
    };
    const response = await PostApponitmentCheckout(object, existing, password);
    if (response) {
      // message.success({
      //   content: "Instant booking done successfully",
      //   style: {
      //     marginTop: "100px",
      //   },
      // });
      AntdCustomMessage({
        type: antdMessageTypes.SUCCESS,
        content: "Instant booking done successfully",
        style: {
          marginTop: "100px",
        },
      });
      //clear data

      setPatientData(null);
      setSelectedPractitioner(null);
      setAppointmentDate(null);
      setIsPatientExist(null);
    }
  };

  const disabledDate = (current)=>{
    return  current && current > new Date()
  }

  return (
    <div className="content">
      <div className="quickbooking-container">
        <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "30px" }}>
          <Col xs={24} sm={24} lg={12}>
            <Form.Item>
              <PhoneInputComponent
                label="Mobile Number"
                idFor="mobile"
                type="hospital"
                placeholder="Enter mobile Number"
                value={mobileNumber.mobile}
                country={mobileNumber.country || "ae"}
                error={error.isError}
                errorMessage={error.message}
                onChange={(value, country) => {
                  setMobileNumber({
                    mobile: value,
                    country: country.dialCode,
                  });
                }}
              />
            </Form.Item>
            <Button
              style={{ marginTop: "-10px", marginBottom: "20px", height: 40 }}
              type="primary"
              onClick={() =>{
                mobileNumber.mobile.length===0 && message.error("Please enter mobile number!");
                mobileNumber.mobile.length > 1 &&
                checkPatientExists({
                  dialercode: mobileNumber.country,
                  mobile: mobileNumber.mobile.substring(
                    mobileNumber.country.length
                  ),
                })
              }}
              loading={isBtnLoading}
            >
              Check Patient
            </Button>
          </Col>
        </Row>
        {isPatientExist !== null ? (
          isPatientExist ? (
            <Form layout="vertical" form={patientExistsForm}>
              <Alert
                message="Patient Already exists"
                type="success"
                closable
                style={{ marginBottom: "1rem", width: "40%" }}
              />
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item label="Name">
                    <Input
                      disabled
                      size="large"
                      type="text"
                      readOnly
                      value={patientData?.patient}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item label="Date of Birth">
                    <Input
                      disabled
                      size="large"
                      type="text"
                      readOnly
                      value={
                        patientData?.dob &&
                        moment(
                          new Date(
                            moment(moment(patientData?.dob, "YYYY-MM-DD"))
                          )
                        ).format("DD MMM YYYY")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["practitioner_id"]}
                    label="Practitioner"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      placeholder="Select practitioners"
                      optionFilterProp="children"
                      size="large"
                      onChange={(val) => setSelectedPractitioner(val)}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      {myPractitioners?.map((item) => (
                        <Select.Option key={item.id}>
                          {item.first_name + " " + item.last_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  {selectedPractitioner && 
                      <ConsultationTypesCard
                        form={patientExistsForm}
                        practitioner_id={selectedPractitioner}
                        triggerChange={(val) => setAppointmentType(val)}
                      />
                  }
                </Col>
                
              </Row>
              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} lg={12}>
                  {selectedPractitioner && (
                    <>
                      <p>Appointment Slot:</p>
                      <ScheduleCard
                        practitioner_id={selectedPractitioner}
                        triggerChange={(val) => setAppointmentDate(val)}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Button
                  loading={ButtonLoading}
                  style={{ height: 40 }}
                  type="primary"
                  disabled={
                    !patientData ||
                    !selectedPractitioner ||
                    !appointmentDate?.date ||
                    !appointmentDate?.time || 
                    !appointmentDate?.token ||
                    !appointmentType
                  }
                  onClick={async () => {
                    setButtonLoading(true);
                    await addBooking(
                      patientData?.id,
                      selectedPractitioner,
                      appointmentDate?.date,
                      appointmentDate?.time,
                      appointmentDate?.token,
                      appointmentType
                    );
                    setButtonLoading(false);
                  }}
                >
                  Book Appointment
                </Button>
              </Row>
            </Form>
          ) : (
            <Form layout="vertical" form={newPatientForm} onFinish={addBookingWithNewPatient}>
              <Alert
                message="Patient not registered"
                description="Please fill the following fields to register user and book appointment."
                type="warning"
                style={{ marginBottom: "1rem" }}
                closable
              />
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["first_name"]}
                    label="First Name"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["last_name"]}
                    label="Last Name"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" type="text" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={{ sm: 8, lg: 24 }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["gender"]}
                    label="Gender"
                    rules={[{ required: true }]}
                  >
                    <Select
                      optionFilterProp="children"
                      size="large"
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      <Select.Option value="male">Male</Select.Option>
                      <Select.Option value="female">Female</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["dob"]}
                    label="Date of Birth"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      size="large"
                      format={"DD MMM YYYY"}
                      style={{
                        width: "100%",
                      }}
                      disabledDate={disabledDate}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} lg={12}>
                  <Form.Item
                    name={["practitioner_id"]}
                    label="Practitioner"
                    rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      style={{ width: "100%" }}
                      size="large"
                      placeholder="Select practitioners"
                      optionFilterProp="children"
                      onChange={(val) => setSelectedPractitioner(val)}
                      getPopupContainer={(triggerNode) =>
                        triggerNode.parentNode
                      }
                    >
                      {myPractitioners?.map((item) => (
                        <Select.Option key={item.id}>
                          {item.first_name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12}>
                  {selectedPractitioner && 
                      <ConsultationTypesCard
                        form={newPatientForm}
                        practitioner_id={selectedPractitioner}
                        triggerChange={(val) => setAppointmentType(val)}
                      />
                  }
                </Col>
              </Row>
              <Row gutter={{ sm: 8, lg: 24 }} style={{ marginTop: "10px" }}>
                <Col xs={24} sm={24} lg={14}>
                  {selectedPractitioner && (
                    <>
                      <p>Appointment Slot:</p>
                      <ScheduleCard
                        practitioner_id={selectedPractitioner}
                        triggerChange={(val) => setAppointmentDate(val)}
                      />
                    </>
                  )}
                </Col>
              </Row>
              <Row
                style={{ justifyContent: "flex-start" }}
                loading={ButtonLoading}
              >
                <Button style={{ height: 40 }} type="primary" htmlType="submit">
                  Register and Book Appointment
                </Button>
              </Row>
            </Form>
          )
        ) : null}
      </div>
    </div>
  );
};
export default QuickBooking;
