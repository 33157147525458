import React, { useState } from 'react';
import RegisterBG from 'Images/bg/login.jpg';
import { 
    Card,
    Typography,
    Form,
    Input,
    Button,
    Select
} from  'antd';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { checkLogin, checkProviderEmailExists, checkProviderMobileExists, providerRegister, providerRegisterResendOTP, providerVerifyOTP } from 'Utils/Services/Auth';
import AntdCustomMessage, { antdMessageTypes } from 'components/AntdCustomMessage';
import md5 from 'md5';
import { useHistory } from "react-router";
import OTPInput from 'react-otp-input';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
const providerTypesObj = {
    PRACTITIONER : "practitioner",
    // CLINIC : "clinic",
    HOSPITAL : "hospital",
    LAB : "lab",
}
const providerTypes = [
    {
        label: "Practitioner",
        id: "practitioner",
        value: "practitioner",
    },
    {
        label: "Hospital / Clinic",
        id: "hospital",
        value: "hospital",
    },
    {
        label: "Lab",
        id: "lab",
        value: "lab",
    },
    // {
    //     label: "Clinic",
    //     id: "clinic",
    //     value: "clinic",
    // },
]

const Register = () => {
    const [form] = Form.useForm();
    const [otpForm] = Form.useForm();
    const [resendOtpForm] = Form.useForm();

    const history = useHistory();
    const [isValid, setIsValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [type, setType] = useState(providerTypesObj.PRACTITIONER);
    const [selectedCountry, setSelectedCountry] = useState({
        name: 'United Arab Emirates', 
        dialCode: '971', 
        countryCode: 'ae',
    });
    const [registered, setRegistered] = useState(false);
    const [OTPVerified, setOTPVerified] = useState(false);
    const [resendOtp, setResendOtp] = useState(false);

    const [OTP, setOTP] = useState("");
    
    const [data,setData] = useState({
        email:"",
        type:providerTypes[0].value,
        first_name: "",
        last_name: "",
        contact_person_first_name:"",
        contact_person_last_name:"",
        lab_name:"",
        clinic_name:"",
        mobile:"",
        password: "",
        confirm_password: "",
    });

    React.useEffect(()=>{
        if(!data?.mobile){
            form.setFields([
                {
                  name: "mobile",
                  errors: ["Please enter mobile number"],
                },
              ]);
        }
    },[data]);

    const [verifyOTPData, setVerifyOTPData] = useState({
        otp:""
    });

    const [resendOTPData, setResendOTPData] = useState({
        email:"",
        type:providerTypes[0].value,
    });

    const handleAlreadyRegistered = () => {
        setResendOtp(true);
        setRegistered(true);
    }

    const handleChange = (otp) => {
        setOTP(otp);
        if (otp.length === 4) {
          otpForm.submit();
        }
    };

    const handleProviderChange = (value) => {
        setType(value);
    };
    const handlePhoneChange = (value,country)=>{
        if(country?.dialCode !== selectedCountry?.dialCode){
            setData(prev=>({...prev,mobile:''}));
            form.setFieldsValue({ mobile: '' });
        }else{
            setData(prev=>({...prev,mobile:value}))
        }
        setSelectedCountry({
            name: country.name, 
            dialCode: country.dialCode, 
            countryCode: country.countryCode,
        });
    }

    const onFinish = async (values) => {
        setIsLoading(true);
        const checkEmailExistsResponse = await checkProviderEmailExists({
            email: values.email,
            type: values.type,
        });
        const checkMobileExistsResponse = await checkProviderMobileExists({
            mobile: values.mobile,
            type: values.type,
        });
        if(checkEmailExistsResponse?.data.length > 0 && checkMobileExistsResponse?.data.length > 0){
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: "Email and Mobile are already registered.",
            });
            setData(values);
            setIsLoading(false);
            return;
        }else if(checkEmailExistsResponse?.data.length > 0){
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: "Email is already registered.",
            });
            setData(values);
            setIsLoading(false);
            return;
        }else if(checkMobileExistsResponse?.data.length > 0){
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: "Mobile is already registered.",
            });
            setData(values);
            setIsLoading(false);
            return;
        }else{
            const registerResponse = await providerRegister({
                ...values,
                ...( values.first_name ? {first_name:values.first_name.replace(/'/g, "''")}:{}),
                ...( values.last_name ? {last_name:values.last_name.replace(/'/g, "''")}:{}),
                ...( values.contact_person_first_name ? {contact_person_first_name:values.contact_person_first_name.replace(/'/g, "''")}:{}),
                ...( values.contact_person_last_name ? {contact_person_last_name:values.contact_person_last_name.replace(/'/g, "''")}:{}),
                ...( values.clinic_name ? {clinic_name:values.clinic_name.replace(/'/g, "''")}:{}),
                ...( values.lab_name ? {lab_name:values.lab_name.replace(/'/g, "''")}:{}),
                password: md5(values.password),
                dialercode: selectedCountry.dialCode,
            });
            if(registerResponse?.status){
                AntdCustomMessage({
                    type: antdMessageTypes.SUCCESS,
                    content: registerResponse?.message,
                });
                setRegistered(true);
                setData({...values});
                setVerifyOTPData({
                    ...verifyOTPData,
                    email: values.email,
                    type: values.type,
                })
            }else{
                AntdCustomMessage({
                    type: antdMessageTypes.ERROR,
                    content: registerResponse.message,
                });
            }
        }
        setIsLoading(false);
      };
    const onFinishFailed = (errorInfo) => {
        setIsLoading(false);
    };
    const onFinishOTP = async (values) => {
        setIsLoading(true);
        const verifyOTPResponse = await providerVerifyOTP({
            ...values,email:data.email, type:data.type
        });
        if(verifyOTPResponse?.status){
            AntdCustomMessage({
                type: antdMessageTypes.SUCCESS,
                content: verifyOTPResponse.message + "Please login with entered credentials and start onboarding.",
            });
            setRegistered(true);
            setOTPVerified(true);
            // const response = await checkLogin({
            //     email:values?.email,
            //     type: values?.type,
            //     password: md5(data?.password),
            // });
            // if(response?.status){
            //     if(values?.type === providerTypesObj.CLINIC){
            //         history.push(`/onboarding/${providerTypesObj.HOSPITAL}`);
            //     }else{
            //         history.push(`/onboarding/${values?.type}`);
            //     }
            // }
            
        }else{
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: verifyOTPResponse?.message,
            });
        }
        setIsLoading(false);
      };
    const onFinishOTPFailed = (errorInfo) => {
        setIsLoading(false);
        AntdCustomMessage({
            type: antdMessageTypes.ERROR,
            content: "OTP verification failed. Please try again.",
        });
    };

    const onFinishResendOtp = async (values) => {
        setIsLoading(true);
        const resendOtpResponse = await providerRegisterResendOTP(values);
        if(resendOtpResponse?.status){
            AntdCustomMessage({
                type: antdMessageTypes.SUCCESS,
                content: resendOtpResponse.message,
            });
            setData({
                ...data,
                email:values.email,
                type:values.type
            });
            setRegistered(true);
            setOTPVerified(false);
            setResendOtp(false);
        }else{
            AntdCustomMessage({
                type: antdMessageTypes.ERROR,
                content: resendOtpResponse?.message,
            });
        }
        setIsLoading(false);
    };
    const onFinishResendFailed = (errorInfo) => {
        AntdCustomMessage({
            type: antdMessageTypes.ERROR,
            content: "Resend otp failed. Please try again.",
        });
    };
    
    if(registered && OTPVerified){
        return <Redirect to="/login"/>
    }else if(!registered && !OTPVerified){return (
        <div
            style={{
                backgroundImage:`url(${RegisterBG})`,
                backgroundSize: "cover",
                minHeight: '100vh',
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{
                margin: "20px 0",
                minHeight: "50vh",
                width: "70%",
                borderRadius: "10px",
                backgroundColor:"rgba(255,255,255,1)",
                // backdropFilter: "blur(10px)",
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className='w-75 p-1 my-4'>
                    <Typography.Title style={{textAlign:"center", fontSize: "22px"}}>Registration</Typography.Title>
                    <p className='text-center'>
                        Already registered, but email not verified?
                        <span 
                            onClick={handleAlreadyRegistered}
                            className="font-italic font-weight-bold"
                            style={{textDecorationLine:"underline"}}
                        >  Click here</span>
                    </p>
                    
                    <Form
                        form={form}
                        name="registerForm"
                        initialValues={data}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                    >
                        
                        <Form.Item
                            label="Email"
                            name="email"
                            rules={[
                                {
                                required: true,
                                message: 'Please input your email id',
                                },
                            ]}
                        >
                            <Input type="email"/>
                        </Form.Item>
                        <Form.Item
                            label="Select"
                            name="type"
                            rules={[
                                {
                                required: true,
                                message: 'Please select type',
                                },
                            ]}
                        >
                            <Select
                                style={{ width: "100%" }}
                                onChange={handleProviderChange}
                                options={providerTypes}
                            />
                        </Form.Item>
                    
                        {
                            type === providerTypesObj.HOSPITAL || type === providerTypesObj.CLINIC ? (
                                <Form.Item
                                label="Enter Clinic / Hospital Name"
                                name="clinic_name"
                                rules={[
                                    {
                                    required: type === providerTypesObj.HOSPITAL || type === providerTypesObj.CLINIC,
                                    message: 'Please input clinic/ hospital name',
                                    }
                                ]}
                            >
                                <Input type="text"/>
                            </Form.Item>
                            ):null
                        }
                        {
                            type === providerTypesObj.LAB ? (
                                <Form.Item
                                    label="Enter Lab Name"
                                    name="lab_name"
                                    rules={[
                                        {
                                        required: type === providerTypesObj.LAB,
                                        message: 'Please input lab name',
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>
                            ):null
                        }
                    {
                        type === providerTypesObj.PRACTITIONER ? (
                            <Form.Item
                                label="Enter Practitioner's first name"
                                name="first_name"
                                rules={[
                                    {
                                    required: type === providerTypesObj.PRACTITIONER,
                                    message: "Please enter practitioner's first name",
                                    },
                                ]}
                            >
                                <Input type="text"/>
                            </Form.Item>
                        ):null
                    }
                        {
                            type === providerTypesObj.PRACTITIONER ? (
                                <Form.Item
                                    label="Enter Practitioner's last name"
                                    name="last_name"
                                    rules={[
                                        {
                                        required: type === providerTypesObj.PRACTITIONER,
                                        message: "Please enter practitioner's last name",
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>
                            ):null
                        }
                        {
                            type === providerTypesObj.HOSPITAL || type === providerTypesObj.CLINIC || type === providerTypesObj.LAB ? (
                                <Form.Item
                                    label="Enter Contact Person's first name"
                                    name="contact_person_first_name"
                                    rules={[
                                        {
                                        required: type === providerTypesObj.HOSPITAL || type === providerTypesObj.CLINIC || type === providerTypesObj.LAB,
                                        message: "Please enter contact person's first name",
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>
                            ):null
                        }
                        {
                            type === providerTypesObj.HOSPITAL || type === providerTypesObj.CLINIC || type === providerTypesObj.LAB ? (
                                <Form.Item
                                    label="Enter Contact Person's last name"
                                    name="contact_person_last_name"
                                    rules={[
                                        {
                                        required: type === providerTypesObj.HOSPITAL || type === providerTypesObj.CLINIC || type === providerTypesObj.LAB,
                                        message: "Please enter contact person's last name",
                                        },
                                    ]}
                                >
                                    <Input type="text"/>
                                </Form.Item>
                            ):null
                        }
                        <Form.Item 
                            name="mobile"
                            label="Phone Number"
                            rules={[
                                {
                                    required: true,
                                    message: "Please input mobile number",
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {      
                                        const countryCode = value?.slice(0,selectedCountry?.dialCode?.length);
                                        if (value && countryCode === "91" && (value?.length-countryCode?.length) !== 10) {
                                            return Promise.reject(`Mobile number should be 10 digits`);
                                        } else if (value && countryCode === "971" && (value?.length-countryCode?.length) !== 9) {
                                            return Promise.reject(`Enter valid Mobile number`);
                                        } else if (value && value?.length - countryCode?.length > 0) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(`Enter Valid Mobile number`);
                                        }
                                    },
                                }),
                            ]}
                        >
                            <PhoneInput
                            inputStyle={{ width: '100%' }}
                            country={selectedCountry.countryCode}
                            value={data.mobile}
                            onChange={handlePhoneChange}
                            />
                        </Form.Item>
                        <Form.Item
                            label="Enter Password"
                            name="password"
                            rules={[
                                {
                                required: true,
                                message: "Please enter password",
                                },
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item
                            label="Confirm Password"
                            name="confirm_password"
                            dependencies={['password']}
                            rules={[
                            { required: true, message: 'Please confirm your password!' },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The passwords that you entered do not match!'));
                                },
                            }),
                            ]}
                        >
                            <Input.Password/>
                        </Form.Item>
                        <Form.Item
                            wrapperCol={{
                                span: 24,
                            }}
                            className='text-center'
                        >
                            <Button 
                                type="primary" 
                                htmlType="submit"
                                // disabled={!isValid}
                                disabled = {!data.mobile}
                                loading={isLoading}
                            >
                                Register
                            </Button>
                        </Form.Item>
                    </Form>
                </div>  
                <p>Already have account <a href="/login" style={{color:"#036FD3",textDecoration:"underline"}}>Login</a></p> 
            </div>
        </div>
    )}else if(registered && !OTPVerified){ return(
        <div
            style={{
                backgroundImage:`url(${RegisterBG})`,
                backgroundSize: "cover",
                minHeight: '100vh',
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div style={{
                minHeight: "50vh",
                width: "70%",
                borderRadius: "10px",
                backgroundColor:"rgba(255,255,255,1)",
                display:"flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <div className='w-75 p-1'>
                    <Typography.Title style={{textAlign:"center"}}>Verify OTP</Typography.Title>
                    {
                        resendOtp ? (
                            <Form
                                form={resendOtpForm}
                                name="resendOtpForm"
                                initialValues={resendOTPData}
                                onFinish={onFinishResendOtp}
                                onFinishFailed={onFinishResendFailed}
                                autoComplete="off"
                                labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                            >
                                
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please input your email id',
                                        },
                                    ]}
                                >
                                    <Input type="email"/>
                                </Form.Item>
                                <Form.Item
                                    label="Select"
                                    name="type"
                                    rules={[
                                        {
                                        required: true,
                                        message: 'Please select type',
                                        },
                                    ]}
                                >
                                    <Select
                                        style={{ width: "100%" }}
                                        options={providerTypes}
                                    />
                                </Form.Item>
                                <Form.Item    
                                    >
                                    <div className='d-flex flex-row justify-center align-items-center'>
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        loading={isLoading}
                                    >
                                        Resend OTP
                                    </Button>
                                    </div>
                                </Form.Item>
                            </Form>
                        ):(
                            <Form
                                form={otpForm}
                                name="otpVerifyForm"
                                initialValues={verifyOTPData}
                                onFinish={onFinishOTP}
                                onFinishFailed={onFinishOTPFailed}
                                autoComplete="off"
                                labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}
                            >
                                <Form.Item
                                    label="Enter OTP sent to your registered email"
                                    name="otp"
                                    className='d-flex flex-column justify-center align-items-center'
                                    rules={[
                                        { required: true, message: 'Please enter OTP' },
                                        {
                                            validator: (_, value) => {
                                                if (value && value.length !== 4) {
                                                    return Promise.reject('OTP must be exactly 4 digits');
                                                } else {
                                                    return Promise.resolve();
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <OTPInput
                                        value={otpForm.getFieldValue('otp')}
                                        onChange={handleChange}
                                        numInputs={4}
                                        separator={<span></span>}
                                        isInputNum={true}
                                        renderInput={(inputProps, index) => (
                                            <input
                                                key={`otp-input-${index}`}
                                                {...inputProps}
                                                style={{ 
                                                    width: '2em', 
                                                    height: '2em', 
                                                    marginRight: '0.2em', 
                                                    marginBottom: '0.5em', 
                                                    fontSize: '1.5em', 
                                                    textAlign: 'center', 
                                                    borderRadius: '5px', 
                                                    border: '1px solid #ccc' 
                                                }}
                                            />
                                        )}
                                    />
                                </Form.Item>
                                <Form.Item    
                                >
                                    <div className='d-flex flex-row justify-center align-items-center'>
                                    <Button 
                                        type="primary" 
                                        htmlType="submit"
                                        loading={isLoading}
                                    >
                                        Submit
                                    </Button>
                                    </div>
                                </Form.Item>    
                            </Form>
                        )
                    }
                    <p className='text-center'>Already have account <a href="/login" style={{color:"#036FD3",textDecoration:"underline"}}>Login</a></p>
                </div>
                
            </div>
        </div>
    )}

}

export default Register